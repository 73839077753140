import React from "react";
import './progressBar.scss';

class ProgressBar extends React.Component {
  render() {
    const { items } = this.props;
    const progressArray = items.map(el => {
      return el.sections && el.sections.map(elem => {
        return elem && elem.progressStatus;
      });
    }).reduce((acc, curr) => {
      return [
        ...acc,
        ...curr,
      ]
    }, []);
     return (
      <div className="progress-bar">
        <div className="completed">
          <span>Выполнены</span>
          <label />
          {progressArray.filter(el => el === 3).length}
        </div>
        <div className="partial">
          <span>Частично</span>
          <label />
          {progressArray.filter(el => el === 2).length}
        </div>
        <div className="uncompleted">
          <span>Не выполнены</span>
          <label />
          {progressArray.filter(el => el === 1).length}
        </div>
      </div>
    )
  }
}

export default ProgressBar;
