import React from "react";
import ReactMarkdown from "react-markdown";
import { Element } from 'react-scroll'
import '../../section.scss';

const STATUS_UNCOMPLETE = 1;
const STATUS_PARTIAL = 2;
const STATUS_COMPLETE = 3;

const iconEye = require('/client/assets/iconEye.png')

class SectionItem extends React.Component {
  copyAnchor(anchor) {
    navigator.clipboard.writeText(`${window.location.origin}/#${anchor}`)
    .then(() => {
      console.log('Text copied to clipboard');
    })
    .catch(err => {
      console.error('Could not copy text: ', err);
    });

  }

  render() {
    const { title, items, description, info, status } = this.props
    let borderColor = 'red';
    switch (status) {
      case STATUS_UNCOMPLETE:
        borderColor = 'red';
        break;
      case STATUS_PARTIAL:
        borderColor = 'yellow';
        break;
      case STATUS_COMPLETE:
        borderColor = 'green';
        break;
      default:
        break;
    }

    const sectionAnchor = description.slice(1, 3).replace(/\)/g, '');

    return (
      <div className={`section-item-wrapper border-${borderColor}`} onClick={() => this.copyAnchor(sectionAnchor)}>
        <label className="anchor" id={sectionAnchor}/>
        <div className={`border border-${borderColor}`}></div>
        <div className="section-item-content">
          <h5 className="section-item-title">{title}</h5>
          <div className="section-item-description">
            <label className={`section-item-description-mark ${/\d\d/i.test(description.slice(0, 4)) ? '' : 'short'}`}>
              {description.slice(0, 4)}
            </label>
            {description.slice(4)}
          </div>
          <section className="section-item">
            {
              Object.values(items).map((el, i) => {
                const text = el;
                const formattedText = el[0] === '(' ? el.slice(4) : el.slice(3);
                const mark = el[0] === '(' ? el.slice(0, 4) : el.slice(0, 3);
                const markClassName = el[0] === '(' ? "section-item-description-mark" : "mark"

                const paragraphAnchor = `${sectionAnchor}-${mark.replace(/[\(\)]/g, '').trim()}`

                return (
                  <>
                    <label className={markClassName}>{mark}</label>
                    <label id={paragraphAnchor} className="anchor"/>
                    <div key={i} className="paragraph">
                      <ReactMarkdown
                        source={formattedText}
                      />
                    </div>
                  </>
                )
              }
              )
            }
          </section>
          {
            info ? (
              <div className="section-item-result-block">
                <img className="section-item-result-icon" src={iconEye} />
                <h5 className="section-item-result-title">Информация о выполнении</h5>
                <p className="section-item-result-text">
                  <ReactMarkdown
                    source={info}
                    escapeHtml={false}
                  />
                </p>
              </div>
            ) : null
          }
        </div>
      </div>
    )
  }
}

export default SectionItem;
