import React from "react";
import ReactDOM from "react-dom";
import textData from './textData';
import './style.scss';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Header from "./components/Header";
import Section from "./components/Section";
import ProgressBar from "./components/ProgressBar";

const logo = require('/client/assets/logo.png')
const arrowUp = require('/client/assets/arrowUp.png')
const homeIcon = require('/client/assets/homeIcon.png')
const iconBook = require('/client/assets/icon4.png')
const iconMan = require('/client/assets/icon5.png')
const iconCage = require('/client/assets/icon3.png')
const iconWoman = require('/client/assets/icon6.png')
const iconMoney = require('/client/assets/icon2.png')
const iconHand = require('/client/assets/icon1.png')

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dbData: [],
      isScrolledDown: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));

    fetch('http://admin.kandk.by/protiv')
      .then(res => res.json())
      .then(res => this.setState({ dbData: res }));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(e) {
    const { isScrolledDown } = this.state;

    if (window.scrollY !== 0 && window.scrollY > 1000 && !isScrolledDown) {
      this.setState({ isScrolledDown: true })
    }
    if (window.scrollY < 1000 && isScrolledDown) {
      this.setState({ isScrolledDown: false })
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  renderSections() {
    const { dbData } = this.state;
    const textDataArray = Object.values(textData);
    const concattedArray = textDataArray.map((elem, index) => {
      const dbDataObj = dbData.find((el) => el.id === index + 1);
      return {
        title: elem.title,
        items: Object.values(elem.items).map((el, i) => {
          return {
            ...el,
            progressInfo: dbDataObj && dbDataObj.sections[i] ? dbDataObj.sections[i].progressInfo : "",
            progressStatus: dbDataObj && dbDataObj.sections[i] ? dbDataObj.sections[i].progressStatus : 1,
          }
        })
      }
    });
    return concattedArray.map((el, i) => <Section key={el.title} sectionTitle={el.title} sectionItems={el.items || {}} sectionId={i+1} />);
  }

  render() {
    const { dbData, isScrolledDown } = this.state;
    return (
      <div className="app">
        <Header />
        <main className="main">
          <Element name="item-0" className="item-0">
            <h2 className="text-header">
              Почему и как Беларусь отчитывается перед КПП ООН?
            </h2>
            <p>
              Беларусь как член ООН в 1987 году ратифицировала <a target="_blank" href="https://www.ohchr.org/RU/ProfessionalInterest/Pages/CAT.aspx">Конвенцию против пыток и других жестоких, бесчеловечных или унижающих достоинство видов обращения и наказания</a>, принятую Генеральной Ассамблей ООН в 1984 году. Наблюдение за выполнением Конвенции осуществляет <a target="_blank" href="https://www.ohchr.org/RU/HRBodies/cat/pages/catindex.aspx">Комитет против пыток</a>, учрежденный в 1987 году. Раз в четыре года каждое государство-участник Конвенции отчитывается перед Комитетом о принятых законодательных и административных мерах по ее выполнению.
            </p>
            <p>
              Беларусь отчитывается в Комитет по упрощенной процедуре: Комитет направляет государству перечень вопросов, и ответы государства на эти вопросы представляют собой его периодический доклад. В 2018 году, на весенней 63-й сессии Комитета Беларусь <a target="_blank" href="https://tbinternet.ohchr.org/_layouts/15/TreatyBodyExternal/Countries.aspx?CountryCode=BLR&Lang=RU">представила</a> пятый периодический доклад. Также коалиция правозащитных организаций страны подала в Комитет <a target="_blank" href="https://tbinternet.ohchr.org/Treaties/CAT/Shared Documents/BLR/INT_CAT_CSS_BLR_30686_R.pdf">альтернативный доклад</a> по соблюдению государством Конвенции.
            </p>
            <p>
              По результатам рассмотрения докладов Комитет принял <a target="_blank" href="https://tbinternet.ohchr.org/_layouts/15/treatybodyexternal/Download.aspx?symbolno=CAT%2fC%2fBLR%2fCO%2f5&Lang=ru">Заключительные замечания</a> в адрес государства. В документе сформулированы рекомендации по предотвращению и искоренению практики пыток и жестокого обращения в Беларуси. Эти рекомендации могут быть приняты к сведению и выполнены государством до представления шестого периодического доклада, к 18 мая 2022 года.
            </p>
            <p>
              В каждой из представленных ниже групп содержатся рекомендации КПП ООН. Их названия и нумерация соответствуют Заключительным замечаниям.
            </p>
            <div className="categories">
              <Link
                className="category-card"
                to="item-1"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-1`} />
                <span>Правовые стандарты и законодательство</span>
              </Link>
              <Link
                className="category-card"
                to="item-2"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-2`} />
                <span>Правоохранительные органы</span>
              </Link>
              <Link
                className="category-card"
                to="item-3"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-3`} />
                <span>Положение в местах несвободы</span>
              </Link>
              <Link
                className="category-card"
                to="item-4"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-4`} />
                <span>Уязвимые группы</span>
              </Link>
              <Link
                className="category-card"
                to="item-5"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-5`} />
                <span>Компенсация жертвам</span>
              </Link>
              <Link
                className="category-card"
                to="item-6"
                spy={true}
                offset={-120}
                smooth={true}
                duration={500}
              >
                <div className={`icon icon-6`} />
                <span>Защита прав человека</span>
              </Link>
            </div>
          </Element>
          <ProgressBar items={dbData} />
          {this.renderSections()}
        </main>
        <footer className="footer">
          <div className="protivpytok">
            <a className="protivpytok-logo protivpytok-link" target="_blank" href="http://protivpytok.org/">
              <img src={logo}/>
            </a>
            <div className="protivpytok-text">
              <span>
                «<a className="protivpytok-link" target="_blank" href="http://protivpytok.org/">Правозащитники против пыток</a>»
              </span>
              <span>2019 год</span>
            </div>
          </div>
          <div className="kandk">
            <span>
              Дизайн и разработка
            </span>
            <span>
              выполнены «<a className="kandk-link" target="_blank" href="https://kandk.team/">‎K&amp;K TEAM</a>»
            </span>
          </div>
        </footer>
        <Link
          className={`scroll-to-top-btn ${isScrolledDown ? 'active' : ''}`}
          to="item-0"
          smooth={true}
          offset={-120}
          duration={500}
        >
          <img src={arrowUp}/>
        </Link>
        <a
          className={`go-home-btn ${isScrolledDown ? 'active' : ''}`}
          href="http://protivpytok.org/"
          target="_blank"
        >
          <img src={homeIcon} />
        </a>
      </div>
    )
  }
}

var mountNode = document.getElementById("app");
ReactDOM.render(<App />, mountNode);
