import React from "react";
import './header.scss';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const logo = require('/client/assets/logo.png')
const iconBook = require('/client/assets/icon4.png')
const iconMan = require('/client/assets/icon5.png')
const iconCage = require('/client/assets/icon3.png')
const iconWoman = require('/client/assets/icon6.png')
const iconMoney = require('/client/assets/icon2.png')
const iconHand = require('/client/assets/icon1.png')

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolledToSection: false,
    }
  }

  handleActive() {
    this.setState({ isScrolledToSection: false });
  }

  handleInactive() {
    this.setState({ isScrolledToSection: true });
  }

  render() {
    const { isScrolledToSection } = this.state;
    const navbarItems = [
      {
        label: "Правовые стандарты",
        target: "item-1",
        src: iconBook,
      },
      {
        label: "Правоохранительные органы",
        target: "item-2",
        src: iconMan,
      },
      {
        label: "Положения в местах несвободы",
        target: "item-3",
        src: iconCage,
      },
      {
        label: "Уязвимые группы",
        target: "item-4",
        src: iconWoman,
      },
      {
        label: "Компенсация",
        target: "item-5",
        src: iconMoney,
      },
      {
        label: "Защита прав человека",
        target: "item-6",
        src: iconHand,
      }
    ];

    return (
      <header className="header">
        <Link
          className="logo"
          to="item-0"
          spy={true}
          offset={-120}
          smooth={true}
          duration={500}
          onSetInactive={this.handleInactive.bind(this)}
          onSetActive={this.handleActive.bind(this)}
        >
          <img src={logo} />
        </Link>
        <div>
          {
            isScrolledToSection ? (
              <nav>
                <Link
                  className="nav-logo"
                  to="item-0"
                  spy={true}
                  offset={-120}
                  smooth={true}
                  duration={500}
                  onSetInactive={this.handleInactive.bind(this)}
                  onSetActive={this.handleActive.bind(this)}
                >
                  <img src={logo} />
                </Link>
                {
                  navbarItems.map((el) => {
                    return (
                      <Link
                        className="navlink"
                        activeClass="active"
                        to={el.target}
                        spy={true}
                        offset={-120}
                        smooth={true}
                        duration={500}
                      >
                        <div  className={`icon icon-${el.target.slice(-1)}`} />
                        <span>{el.label}</span>
                      </Link>
                    )
                  })

                }
              </nav>
            ) : (
              <span className="welcome-text">
                Мониторинг выполнения рекомендаций Комитета против пыток ООН по итогам пятого периодического доклада Беларуси
              </span>
            )
          }
        </div>
      </header>
    )
  }
}

export default Header
