import React from "react";
import './section.scss';

import SectionItem from './components/SectionItem';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const iconBook = require('/client/assets/icon4.png')
const iconMan = require('/client/assets/icon5.png')
const iconCage = require('/client/assets/icon3.png')
const iconWoman = require('/client/assets/icon6.png')
const iconMoney = require('/client/assets/icon2.png')
const iconHand = require('/client/assets/icon1.png')

const iconsList = [
  {
    title: "Правовые стандарты и законодательство",
    src: iconBook,
  },
  {
    title: "Правоохранительные органы",
    src: iconMan,
  },
  {
    title: "Положение в местах несвободы",
    src: iconCage,
  },
  {
    title: "Уязвимые группы",
    src: iconWoman,
  },
  {
    title: "Компенсация жертвам",
    src: iconMoney,
  },
  {
    title: "Защита прав человека",
    src: iconHand,
  }
];

class Section extends React.Component {
  render() {
    const { sectionTitle, sectionItems, sectionId } = this.props
    const iconSrc = iconsList.find((el) => el.title === sectionTitle).src;

    return (
      <Element name={`item-${sectionId}`} className="section-wrapper">
        <img className="section-icon" src={iconSrc} />
        <h3 className="section-title">{sectionTitle}</h3>
        <section className="section">
          {
            Object.values(sectionItems).map(el => <SectionItem title={el.title} items={el.subitems} description={el.description} key={el.title} info={el.progressInfo} status={el.progressStatus} />)
          }
        </section>
      </Element>
    )
  }
}

export default Section;
